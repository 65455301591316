import { getUrl } from './base-url';
import { website } from './website';
import type { BreadcrumbList, ImageObject, Thing, WebSite } from 'schema-dts';

export function getSchemaString(graph: Array<Thing>) {
	return JSON.stringify({
		'@context': 'https://schema.org',
		'@graph': [
			...graph,
			website({
				name: 'Troon Rewards',
				description:
					'Troon Rewards is the best way to book tee times at Troon courses around the world. Find exclusive rates and tee times. Earn points towards complimentary rounds of golf. Sign up today!',
				search: '/courses/search?q={search_term_string}',
			}),
		].map((item) => {
			if (!item || typeof item !== 'object') {
				return item;
			}

			if (item['@type'] === 'WebPage') {
				useThing<BreadcrumbList>(graph, 'BreadcrumbList', () => {
					item.breadcrumb = { '@id': getUrl(`${item.url ?? ''}`, 'breadcrumblist') };
				});
				useThing<WebSite>(graph, 'WebSite', (site) => {
					item.isPartOf = site['@id'] ? { '@id': site['@id'] } : undefined;
				});
				useThing<ImageObject>(graph, 'ImageObject', (image) => {
					item.primaryImageOfPage = image['@id'] ? { '@id': image['@id'] } : undefined;
				});
			}

			return item;
		}),
	});
}

function useThing<T extends Thing>(things: Array<Thing>, type: string, fn: (thing: T) => void) {
	const thing = things.find((thing) => thing && typeof thing === 'object' && thing['@type'] === type);
	if (thing) {
		fn(thing as T);
	}
}
