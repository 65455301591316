import { getUrl } from './base-url';
import type { WebSite } from 'schema-dts';

export function website({
	name,
	description,
	search,
}: {
	name: string;
	description: string;
	search?: string;
}): WebSite {
	return {
		'@type': 'WebSite',
		'@id': getUrl('/', 'website'),
		url: getUrl('/'),
		name,
		description,
		potentialAction: search
			? {
					'@type': 'SearchAction',
					target: {
						'@type': 'EntryPoint',
						urlTemplate: getUrl(search),
					},
					// @ts-expect-error google extends the schema https://developers.google.com/search/docs/appearance/structured-data/sitelinks-searchbox
					'query-input': 'required name=search_term_string',
				}
			: undefined,
	};
}
